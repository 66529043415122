import React, { ReactNode } from "react"

import Container from "@ecom/ui/components/Container"

import * as styles from "./howToGetCard.module.scss"

const STEPS = [
  {
    title: (
      <>
        Оставьте заявку. <span>Онлайн за&nbsp;несколько&nbsp;минут</span>
      </>
    ),
  },
  {
    title: (
      <>
        Получите карту. <span>Доставкой или&nbsp;в&nbsp;отделении банка</span>
      </>
    ),
  },
  {
    title: (
      <>
        Активируйте карту. <span>Совершив покупку в&nbsp;партнерском магазине</span>
      </>
    ),
  },
]

type ItemsProps = {
  title: ReactNode
}

type Props = {
  orderNum?: string
  title?: ReactNode
  items?: ItemsProps[]
}

const titleDefault = (
  <>
    Как оформить карту <br className="d-sm-none" /> рассрочки «Халва»
  </>
)

export const HowToGetCard = ({ orderNum, title = titleDefault, items = STEPS }: Props) => (
  <section className={styles.section} data-exclude={orderNum}>
    <Container className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <div>
        <ul className={styles.steps}>
          {items.map((item, i) => (
            <li key={i} className={styles.step}>
              <p className={styles.text}>{item.title}</p>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  </section>
)
